import { gql } from '@apollo/client';

import { speakerWithRatingFragment } from 'src/components/Event/graphql';
import { EReportJobReason } from 'src/components/ReportModalV2/messages';

const JobFields = gql`
  fragment JobFields on Job {
    id
    type
    title
    workArrangementOption
    minYearsOfExperience
    maxYearsOfExperience
    createdAt
    updatedAt
    descriptionJsonString
    interviewProcessJsonString
    canUserApplyWithReason {
      canApply
      reason
    }
    isCoverLetterMandatory
    externalApplyURL
    acceptsForeignApplications
    CountryCode
    expiryDate
    CityId
    status
    shouldShowBenefits
    benefits {
      benefit
      description
      title
      logo
    }
    isActivelyHiring
    isHot
    gender
    minAge
    maxAge
  }
`;

export const CompanyFields = gql`
  fragment CompanyFields on Company {
    id
    name
    logo
    status
    isVIP
    IndustryId
    industry {
      id
      name
    }
  }
`;

export const LocationFields = gql`
  fragment LocationFields on HierarchicalLocation {
    id
    name
    administrativeLevelName
    formattedName
    level
    slug
    parents {
      id
      name
      administrativeLevelName
      formattedName
      level
      slug
      parents {
        level
        formattedName
        slug
      }
    }
  }
`;

export const HierarchicalJobCategoryFields = gql`
  fragment HierarchicalJobCategoryFields on HierarchicalJobCategory {
    HierarchicalJobCategoryId: id
    name
    level
    parents {
      HierarchicalJobCategoryId: id
      name
      level
    }
  }
`;

export const CityFields = gql`
  fragment CityFields on City {
    id
    name
  }
`;

export const CountryFields = gql`
  fragment CountryFields on Country {
    code
    name
  }
`;

export const CitySubDivisionFields = gql`
  fragment CitySubDivisionFields on CitySubDivision {
    id
    name
  }
`;

export const SalaryFields = gql`
  fragment SalaryFields on JobSalary {
    id
    salaryType
    salaryMode
    maxAmount
    minAmount
    CurrencyCode
  }
`;

export const CreatorFields = gql`
  fragment CreatorFields on Creator {
    id
    lastSeen
    name
    image
    online
    isHighResponseRate
  }
`;

export const queryGetJobDetailsById = gql`
  query getJobDetailsById(
    $opportunityId: String!
    $traceInfo: String
    $source: String
  ) {
    getJobById(id: $opportunityId, traceInfo: $traceInfo, source: $source) {
      company {
        ...CompanyFields
        IndustryId
        descriptionJsonString
        isApprovedToTalentHunt
        website
        socialMediaSitesJsonString
        photosJsonString
        size
        address
      }
      hierarchicalJobCategory {
        HierarchicalJobCategoryId: id
        name
        level
        parents {
          HierarchicalJobCategoryId: id
          name
          level
        }
      }
      benefits {
        benefit
        description
        title
        logo
      }
      fraudReportFlag
      shouldShowSalary
      educationLevel
      resumeRequiredStatus
      salaryEstimate {
        minAmount
        maxAmount
        CurrencyCode
      }
      citySubDivision {
        ...CitySubDivisionFields
      }
      city {
        ...CityFields
      }
      country {
        ...CountryFields
      }
      skills {
        skill {
          id
          name
        }
        mustHave
      }
      salaries {
        ...SalaryFields
      }
      location {
        ...LocationFields
      }
      expInfo
      creatorResponseTime
      ...JobFields
      creator {
        ...CreatorFields
      }
      isEducationRequiredForCandidate
      isLocationRequiredForCandidate
      traceInfo
    }
  }
  ${JobFields}
  ${CompanyFields}
  ${CityFields}
  ${CountryFields}
  ${SalaryFields}
  ${CitySubDivisionFields}
  ${LocationFields}
  ${CreatorFields}
`;

const commonSimilarJobsFields = `
  id
  title
  createdAt
  updatedAt
  city {
    ...CityFields
  }
  shouldShowSalary
  educationLevel
  salaryEstimate {
    minAmount
    maxAmount
    CurrencyCode
  }
  country {
    ...CountryFields
  }
  company {
    ...CompanyFields
  }
  salaries {
    ...SalaryFields
  }
  location {
    ...LocationFields
  }
  hierarchicalJobCategory {
    HierarchicalJobCategoryId: id
    name
    level
    parents {
      HierarchicalJobCategoryId: id
      name
      level
    }
  }
`;

const createSimilarJobsQuery = (resultWrapper: string) => gql`
  query getSimilarJobs($similarJobsInput: GetSimilarJobsInput!) {
    getSimilarJobs(data: $similarJobsInput) {
      ${resultWrapper}
    }
  }
  ${CompanyFields}
  ${CityFields}
  ${CountryFields}
  ${SalaryFields}
  ${LocationFields}
`;

export const queryGetSimilarJobs = createSimilarJobsQuery(
  commonSimilarJobsFields
);

export const queryGetSimilarJobsV2 = createSimilarJobsQuery(`
  list {
    traceInfo
    isApplied
    ${commonSimilarJobsFields}
  }
  expInfo
`);

export const getCompanyById = gql`
  query getCompanyById($id: String!, $data: GetJobsByCompanyInput!) {
    getCompanyById(id: $id) {
      id
      IndustryId
      jobs(data: $data) {
        id
        title
        status
        source

        createdAt
        updatedAt
        attachmentsJsonString
        type
        shouldShowSalary
        educationLevel
        country {
          code
          name
        }
        city {
          id
          name
        }
        company {
          id
          name
          logo
          IndustryId
        }
        salaries {
          id
          minAmount
          maxAmount
          salaryMode
          salaryType
          CurrencyCode
        }
        location {
          ...LocationFields
        }
        hierarchicalJobCategory {
          HierarchicalJobCategoryId: id
          name
          level
          parents {
            HierarchicalJobCategoryId: id
            name
            level
          }
        }
      }
    }
  }
  ${LocationFields}
`;

export const getJobRelatedExpertClasses = gql`
  query getJobRelatedExpertClasses($jobId: String!) {
    getJobRelatedExpertClasses(jobId: $jobId) {
      expertClasses {
        id
        shortId
        title
        bannerPic
        startDate
        endDate
        ticketTypes {
          price
          currency
          defaultDiscount {
            discountedPrice
            applicableDiscountPercentage
          }
        }
        numberOfSessions
        classType {
          id
          name
        }
        categories {
          id
          name
        }
        speakers {
          ...speakerWithRatingFragment
        }
      }
      expertClassCategories {
        id
        name
      }
      totalCount
    }
  }
  ${speakerWithRatingFragment}
`;

export const createStreamChannelForApplicationIDMutation = gql`
  mutation createStreamChannelForApplicationID($id: UUID!) {
    createStreamChannelForApplicationID(id: $id)
  }
`;

export type ReportJobInput = {
  data: {
    jobId: string;
    reason: EReportJobReason;
    reportDetails: string;
    attachments: string[];
  };
};

export const reportJobMutation = gql`
  mutation reportJob($data: ReportJobInput!) {
    reportJob(data: $data)
  }
`;

export const getJobHiringQuestions = gql`
  query jobHiringQuestion($jobId: UUID!) {
    getJobHiringQuestions(jobId: $jobId) {
      predefinedQuestions {
        name
        type
        required
      }
      employerScreeningQuestions {
        label
        labelLokaliseVariables {
          key
          value
        }
        labelLokaliseKey
        questionType
        name
        questions {
          id
          subLabel
          subLabelLokaliseKey
          responseOptions {
            value
            lokaliseKey
            mappedValue
          }
        }
      }
    }
  }
`;

export const queryGetJobAdditionalInfoById = gql`
  query getJobDetailsById($id: String!, $traceInfo: String, $source: String) {
    getJobById(id: $id, traceInfo: $traceInfo, source: $source) {
      creatorResponseTime
      creator {
        ...CreatorFields
      }
      gender
      minAge
      maxAge
      traceInfo
    }
  }
  ${CreatorFields}
`;
