import get from 'lodash/get';
import { createSelector } from 'reselect';

import { State } from 'src/global/store';
import { getCountries } from 'src/selectors/api';

export const getUser = (state: State) => state.user.me.value;
export const getUserIntro = (state: State) => getUser(state).intro;
export const getUserPortfolio = (state: State) => getUser(state).portfolio;

export const getScores = (state: State) => state.user.me.scores;
export const getProfileCompletionScore = (state: State) =>
  (getScores(state).value / getScores(state).total) * 100;

export const getUserId = (state: State) => getUser(state)?.id ?? null;
export const getUserEntity = (state: State) =>
  getUserId(state) ? state.entities.user[getUserId(state)] : null;

export const getMeResume = (state: State): string =>
  get(state, 'user.me.value.resume');

export const getFetchingMe = (state: State) => get(state, 'user.me.fetching');

export const getFetchingScores = (state: State) =>
  get(state, 'user.me.scores.fetching');

export const getAmplitudeUserProperties = (state: State) => {
  const {
    role,
    email,
    CountryCode,
    firstName,
    lastName,
    phone,
    whatsappNumber,
  } = getUser(state);

  return {
    countryCode: CountryCode,
    email,
    userId: getUserId(state),
    role,
    firstName,
    lastName,
    phone,
    has_whatsapp_number: Boolean(whatsappNumber),
  };
};

export const getErrorDetails = (state: State) =>
  get(state, 'user.me.errorDetails');

export const getUserRole = (state: State) => get(state, 'user.me.value.role');

const getUserCountryCode = (state: State) => getUser(state).CountryCode;
export const getUserCountry = createSelector(
  [getCountries, getUserCountryCode],
  (countries, countryCode) => countries[countryCode]
);
