import { get, uniqBy } from 'lodash';

import { Company } from 'src/global/models';
import { JobInterface } from 'src/modules/Opportunity/interface';
import {
  HierarchicalJobCategory,
  HierarchicalJobCategoryL1,
  HierarchyJobCategoryBase,
  HierarchyJobRolePreferenceCategory,
  HierarchyJobRolePreferences,
  JobRolePreferencesQueryData,
} from 'src/modules/Profile/graphql/jobCategoriesAndTitles';

import { CountryCodes } from '../enums';

const validCountriesForHierarchicalJobCategory = [
  CountryCodes.ID,
  CountryCodes.VN,
];

export const enum JOB_CATEGORY_LEVEL {
  L1 = 1,
  L2 = 2,
  L3 = 3,
}

export const validCountryForHierarchicalJobCategory = (
  countryCode: CountryCodes
) => validCountriesForHierarchicalJobCategory.includes(countryCode);

export const getL1ParentsFlat = (
  jobRolePreferences: HierarchyJobRolePreferences[]
) => {
  return jobRolePreferences.flatMap((l3) =>
    get(l3, 'HierarchicalJobCategory.parents', []).filter(
      (parent: HierarchicalJobCategory) =>
        parent.level === JOB_CATEGORY_LEVEL.L1
    )
  );
};

export const getUniqueL1ParentsFlat = (
  jobRolePreferences: HierarchyJobRolePreferences[]
) => {
  return uniqBy(
    getL1ParentsFlat(jobRolePreferences),
    'HierarchicalJobCategoryId'
  );
};

export const isL1Parent = (parent: { level: number }) =>
  parent.level === JOB_CATEGORY_LEVEL.L1;

export const isL2Parent = (parent: { level: number }) =>
  parent.level === JOB_CATEGORY_LEVEL.L2;

export const pickJobRolePreferences = (data: JobRolePreferencesQueryData) =>
  get(data, 'jobRolePreferences', []) as HierarchyJobRolePreferences[];

export const getL1HierarchicalCategory = (
  hierarchicalCategory: HierarchicalJobCategory
): HierarchicalJobCategoryL1 | null => {
  if (hierarchicalCategory.level === JOB_CATEGORY_LEVEL.L1) {
    return hierarchicalCategory;
  }
  const parents = hierarchicalCategory?.parents;
  if (parents) {
    return (
      (parents as Array<HierarchicalJobCategoryL1>).find(isL1Parent) ?? null
    );
  }
  return null;
};

type getOpportunityCategoryNameAndSlug = {
  categoryName?: string;
};

export const getOpportunityCategoryName = (
  opportunity: JobInterface
): getOpportunityCategoryNameAndSlug => {
  const hierarchyJobRolePreferenceCategory = get(
    opportunity,
    'hierarchicalJobCategory'
  ) as HierarchyJobRolePreferenceCategory;

  if (hierarchyJobRolePreferenceCategory) {
    const parents = hierarchyJobRolePreferenceCategory.parents;
    return {
      categoryName:
        parents?.find(isL1Parent)?.name ??
        hierarchyJobRolePreferenceCategory.name,
    };
  } else {
    return {
      categoryName: '',
    };
  }
};

type GetHierarchicalJobCategoryReturn = {
  jobCategoryName: string;
  jobSubCategoryName?: string;
  jobRoleName?: string;
  jobCategoryId?: string;
  jobSubCategoryId?: string;
  jobRoleCategoryId?: string;
  company: Company;
};

export const getHierarchicalJobCategory = (
  opportunity: JobInterface
): GetHierarchicalJobCategoryReturn => {
  const company = opportunity?.company;
  const hierarchicalCategory = opportunity?.hierarchicalJobCategory;
  const jobCategory = hierarchicalCategory?.parents.filter(isL1Parent)[0];
  const jobSubCategory = hierarchicalCategory?.parents.filter(isL2Parent)[0];

  const jobRoleName =
    hierarchicalCategory?.level === JOB_CATEGORY_LEVEL.L3 &&
    hierarchicalCategory?.name;

  const { categoryName } = getOpportunityCategoryName(opportunity);

  return {
    jobCategoryName: categoryName,
    jobSubCategoryName: jobSubCategory?.name ?? '',
    jobRoleName: jobRoleName ?? '',
    jobCategoryId: jobCategory?.HierarchicalJobCategoryId ?? '',
    jobSubCategoryId: jobSubCategory?.HierarchicalJobCategoryId ?? '',
    jobRoleCategoryId: hierarchicalCategory?.HierarchicalJobCategoryId ?? '',
    company,
  };
};

export const getHierarchicalJobCategorySlugs = (
  jobCategoryId: string,
  jobSubCategoryId: string,
  jobRoleCategoryId: string,
  queryData: {
    getHierarchicalJobCategoriesByIds: HierarchyJobCategoryBase[];
  }
) => {
  const findSlugByLevel = (level: number): string => {
    return queryData?.getHierarchicalJobCategoriesByIds.find(
      (category) => category.level === level
    )?.slug;
  };

  const jobCategorySlug = findSlugByLevel(JOB_CATEGORY_LEVEL.L1);
  const jobSubCategorySlug = findSlugByLevel(JOB_CATEGORY_LEVEL.L2);
  const jobRoleCategorySlug = findSlugByLevel(JOB_CATEGORY_LEVEL.L3);

  return {
    jobCategorySlug: jobCategorySlug,
    jobSubCategorySlug: jobSubCategorySlug,
    jobRoleCategorySlug: jobRoleCategorySlug,
  };
};
