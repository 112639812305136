import { isEmpty } from 'lodash';
import { ParsedUrlQuery } from 'querystring';

import { generateSlug } from 'src/common/generateSlug';
import {
  ID_JOB_EXPLORE_URL,
  JOB_EXPLORE_URL,
  ROUTES,
  VN_JOB_EXPLORE_URL,
} from 'src/common/routes';
import { Company } from 'src/global/models/Company';
import { Event } from 'src/global/models/Event';
import { Job } from 'src/global/models/Job';
import { createUrlSlug } from 'src/modules/Opportunities/utils/createUrlSlug';

import { UTM_REFERRER } from '../../modules/Opportunities/constants';
import { SHARE_ID, UTM_CAMPAIGN, UTM_MEDIUM } from '../constants';
import { CountryCodes } from '../enums';

export const getJobWithCountryFilterURL = (
  country: CountryCodes,
  canApplyCountriesQuery: boolean
) => {
  const isID = country === CountryCodes.ID;
  const isVN = country === CountryCodes.VN;

  return isID
    ? ID_JOB_EXPLORE_URL
    : isVN
    ? VN_JOB_EXPLORE_URL
    : country && canApplyCountriesQuery
    ? JOB_EXPLORE_URL
    : `/${ROUTES.opportunitiesJobsExplore}`;
};

export const getJobWithCompanyFilterURL = (
  companyId: string,
  companyName: string,
  country?: CountryCodes,
  language?: string,
  query?: string
) => {
  return companyId
    ? `/company/${createUrlSlug({
        slug: generateSlug(companyName),
        countryCode: country,
        language,
      })}/${companyId}?${query}`
    : `/${ROUTES.opportunitiesJobsExplore}?${query}`;
};

/**
 * Given a company, generate and return the props for Link.
 *
 * @param company Company object
 */
export const getCompanyLinkProps = (company: Company) => {
  if (isEmpty(company)) {
    return null;
  }

  const id = company.id;
  const companyName = generateSlug(company.name);
  const as = companyName
    ? `/${ROUTES.companies}/${companyName}/${id}`
    : `/${ROUTES.companies}/${id}`;

  return {
    href: {
      pathname: `/${ROUTES.company}`,
      query: {
        id,
        companyName,
      },
    },
    as,
  };
};

export const getJobLinkProps = (
  opportunity: Partial<Job>,
  referrer?: UTM_REFERRER,
  currentURLSearchParams?: URLSearchParams
) => {
  const id = opportunity.id;
  const title = generateSlug(opportunity.title);
  const queryParams: string[] = [];

  const utmReferrer = currentURLSearchParams?.get('utm_referrer');
  const utmMedium = currentURLSearchParams?.get(UTM_MEDIUM);
  const utmCampaign = currentURLSearchParams?.get(UTM_CAMPAIGN);
  const shareId = currentURLSearchParams?.get(SHARE_ID);

  const addQueryParam = (key: string, value?: string | null) => {
    if (value) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  };

  addQueryParam(UTM_MEDIUM, utmMedium);
  addQueryParam(UTM_CAMPAIGN, utmCampaign);
  addQueryParam(SHARE_ID, shareId);
  addQueryParam('utm_referrer', referrer ?? utmReferrer);
  addQueryParam('traceInfo', opportunity.traceInfo);

  const query = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

  return {
    href: {
      pathname: `/${ROUTES.opportunitiesJob}`,
      query: {
        id,
        title,
        utm_referrer: referrer,
      },
    },
    as: `/${ROUTES.opportunitiesJobs}/${title}/${id}${query}`,
  };
};

export const getApplyJobLinkProps = (
  opportunity: Partial<Job>,
  queryParam: ParsedUrlQuery
) => {
  const props = getJobLinkProps(opportunity);
  const queryParamsString = Object.keys(queryParam)
    .map((key) => `${key}=${queryParam[key]}`)
    .join('&');

  return {
    href: props.href,
    as: `${props.as}/apply${queryParamsString ? `?${queryParamsString}` : ''}`,
    query: queryParam,
  };
};

export const getExpertClassLinkProps = (expertClass: Partial<Event>) => {
  const titleSlug = generateSlug(expertClass.title);
  return {
    href: `/${ROUTES.expertClassDetail}?title=${titleSlug}&shortId=${expertClass.shortId}`,
    as: `/${ROUTES.expertClass}/${titleSlug}/${expertClass.shortId}`,
  };
};

export const getExpertClassOrderLinkProps = (order: { id: string }) => {
  const { id } = order;

  return {
    href: {
      pathname: `/${ROUTES.expertClassOrders}/id`,
      query: {
        id,
      },
    },
    as: `/${ROUTES.expertClassOrders}/${id}`,
  };
};

export const getExpertClassConfirmOrderPageUrl = (expertClassShortId: string) =>
  `/${ROUTES.confirmOrder}?expertClassId=${expertClassShortId}`;
