import { Device } from '@artsy/detect-responsive-traits';
import App, { AppContext, AppProps } from 'next/app';
import { v4 as uuidv4 } from 'uuid';

import { pushAsPath } from 'src/actions/history';
import { setUserAgent } from 'src/actions/server';
import { AsyncReducerKeys, DstStore, State } from 'src/global/store';
import createApolloClient from 'src/modules/ApolloWrapper/createApolloClient';
import { fetchUserData } from 'src/modules/Session/Actions';
import { initialState as initialSessionState } from 'src/modules/Session/Reducer';
import { fetchFeatures } from 'src/modules/Unleash/Actions';
import { entitiesInitialState } from 'src/staticReducers';

import { DstContext } from '../../shared/interfaces/DstContext';
import { getResponsiveRenderProps } from '../modules/Global/responsive';
import { makeServerStore } from '../modules/Global/store';
import { getLocaleMessages } from '../modules/Intl/utils';

export interface DstServerAppProps
  extends AppProps<{ asyncReducerKeys: AsyncReducerKeys; statusCode: number }> {
  dstContext: DstContext;
  initialReduxState: State;
  responsiveRenderProps: {
    device: Device;
    onlyMatch: any[];
  };
  apolloCache: any;
  apolloClient: any;
}

function initServerApp(AppComponent: typeof App) {
  return class ServerApp extends App<DstServerAppProps> {
    // This will only execute once on the server per request on the initial page load.
    static async getInitialProps(appContext: AppContext) {
      const dstContext = appContext.ctx.req.dstContext;
      const clientConfig = dstContext.clientConfig;
      const asPath = appContext.ctx.asPath;

      // fetch locale messages
      dstContext.intl.messages = await getLocaleMessages(
        dstContext.intl.locale,
        clientConfig
      );

      let cookie = '';
      let deviceId = '';

      if (appContext.ctx.req && appContext.ctx.req.headers.cookie) {
        cookie = appContext.ctx.req.headers.cookie;

        // Parse the cookie string into an object
        const cookies = cookie
          .split(';')
          .reduce<Record<string, string>>((acc, part) => {
            const [key, value] = part.trim().split('=');
            acc[key] = decodeURIComponent(value);
            return acc;
          }, {});

        deviceId = cookies['device_id'];
      }

      if (!deviceId) {
        deviceId = uuidv4();
        const expires = new Date();
        expires.setTime(expires.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days from now

        if (appContext.ctx.res) {
          appContext.ctx.res.setHeader(
            'Set-Cookie',
            `device_id=${deviceId}; HttpOnly; Path=/; Expires=${expires.toUTCString()}`
          );
        }
      }

      // initial redux state.
      const initialReduxState = {
        clientConfig,
        entities: entitiesInitialState,
        session: {
          ...initialSessionState,
          token: dstContext.accessToken,

          // for syncing cookie from client when fetching data on server side in order to keep the same session
          //   this will be deleted in constructor to avoid unnecessary redux store data on client
          cookie,
        },
      };

      const reduxStore = makeServerStore(
        initialReduxState,
        appContext.ctx.req.headers
      );

      // store current asPath
      reduxStore.dispatch(pushAsPath(asPath));

      reduxStore.dispatch(setUserAgent(dstContext.userAgent));

      if (dstContext.isAuthenticated) {
        // fetchUserData wrap all the async requests in a Promise.all
        // fetchFeatures is included in it
        await reduxStore.dispatch(fetchUserData());
      } else {
        await reduxStore.dispatch(fetchFeatures());
      }

      // obtain properties needed by certain context providers to render responsively on the server.
      // eg: MediaContextProvider (@artsy/fresnel)
      const responsiveRenderProps = getResponsiveRenderProps(
        appContext.ctx.req
      );

      // init apollo client
      const apolloClient = createApolloClient({
        listenPort: dstContext.listenPort,
        token: dstContext.accessToken,
        cookie,
        ssrToken: dstContext.ssrToken,
        remoteAddress: dstContext.remoteAddress,
        xForwardedFor: appContext.ctx.req.headers['x-forwarded-for'],
        locale: clientConfig.LANG,
      });
      // overwrite toJSON to eliminate nextjs initialProps serialization error
      // __NEXT_DATA__.props.apolloClient will be null
      (apolloClient as any).toJSON = (): any => {
        return null;
      };

      const reduxState = reduxStore.getState();

      // pass these props to AppTree when calling
      // apollo's getDataFromTree in withApollo page components
      const appTreeProps = {
        dstContext,
        initialReduxState: reduxState,
        responsiveRenderProps,
        // apolloClient will be passed to AppTree as prop in withApollo
        apolloClient,
      };

      appContext.ctx.reduxStore = reduxStore;
      appContext.ctx.apolloClient = apolloClient;
      appContext.ctx.appTreeProps = appTreeProps;

      // if page is wrapped by withApollo
      // App.getInitialProps will run withApollo.getInitialProps
      // which fills apolloClient's cache with query data
      // this is the first time apolloClient being passed to AppComponent
      const appInitialProps = await App.getInitialProps(appContext);

      // apolloClient is in appTreeProps
      // when this object being returned
      // it will be the second time apolloClient being passed to AppComponent
      return {
        ...appInitialProps,
        ...appTreeProps,
        // now we can extract cache
        // and it will be available at client side
        // from window.__NEXT_DATA_.props.apolloCache
        apolloCache: apolloClient.extract(),
        initialReduxState: reduxStore.getState(),
      };
    }

    private reduxStore: DstStore;

    constructor(props: DstServerAppProps) {
      super(props);
      delete props.initialReduxState.session.cookie;
      this.reduxStore = makeServerStore(props.initialReduxState);
    }

    render() {
      return (
        <AppComponent
          {...this.props}
          reduxStore={this.reduxStore}
          // at server side, apolloClient is passed to AppComponent 2 times
          // 1. withApollo passes a client without cache data
          // 2. nextjs passes the same client instance but with cache data fetched in withApollo
          apolloClient={this.props.apolloClient}
        />
      );
    }
  };
}

export default initServerApp;
