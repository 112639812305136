import 'react-toastify/dist/ReactToastify.css';

import React, { Component } from 'react';
import NoSSR from '@mpth/react-no-ssr';
import Router, { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import Favicon from 'react-favicon';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { compose } from 'redux';

import {
  GTMSetUser,
  GTMSetUserWithCallbackAction,
} from 'src/actions/googleTagManager';
import { LogoutListener } from 'src/modules/Session/RenderLessComponents/LogoutListener';
import { LogoutUserWhenTokenIsNotValid } from 'src/modules/Session/RenderLessComponents/LogoutUserWhenTokenIsNotValid';
import {
  getProfileCompletionScore,
  getUser,
  getUserId,
} from 'src/selectors/user';

import { dismissCloseableNotification } from './actions/notifications';
import { getIsTokenScoped } from './common/helpers';
import MarketingParams from './components/MarketingParams';
import RedirectToExternalLinkModal from './components/Modals/RedirectToExternalLinkModal';
import { Alert } from './MainLayout.sc';
import { getConfig } from './modules/ClientConfig';
import { fetchGeneralConfig } from './modules/GeneralConfig/Actions';
import MainContainer from './modules/MainContainer/MainContainer';
import { logout } from './modules/Session/Actions';
import { getScopedTokenActionCompleted } from './modules/Session/Selectors';
import {
  getNotificationMessage,
  getNotificationPosition,
  getNotificationState,
  getNotificationType,
} from './selectors/notifications';

class MainLayout extends Component {
  static propTypes = {
    notificationOpen: PropTypes.bool,
    scopedTokenActionCompleted: PropTypes.bool,

    autoCloseInterval: PropTypes.number,
    defaultAutoCloseInterval: PropTypes.number,
    userId: PropTypes.string,
    user: PropTypes.object,
    profileCompletionScore: PropTypes.number,
    notificationType: PropTypes.string,
    faviconURL: PropTypes.string,

    dismissCloseableNotification: PropTypes.func,
    fetchGeneralConfig: PropTypes.func,
    logout: PropTypes.func,
    GTMSetUser: PropTypes.func.isRequired,
    GTMSetUserWithCallbackAction: PropTypes.func.isRequired,

    children: PropTypes.element,
    router: PropTypes.object,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    notificationPosition: PropTypes.string,
  };

  static defaultProps = {
    faviconURL: 'images/favicon.ico',
  };

  async componentDidMount() {
    // fetch general config from the server which contains the configuration for some of the features.
    // eg: campaigns display frequency
    this.props.fetchGeneralConfig();
    this.props.GTMSetUser();
  }

  async componentDidUpdate(prevProps) {
    const params = Router.query;
    const { utm_campaign, utm_referrer, utm_medium, utm_source } = params || {};

    if (
      this.props.scopedTokenActionCompleted &&
      prevProps.scopedTokenActionCompleted !==
        this.props.scopedTokenActionCompleted
    ) {
      const isScoped = await getIsTokenScoped();
      if (isScoped) {
        setTimeout(() => this.props.logout(), 3000);
      }
    }

    if (this.props.userId !== prevProps.userId) {
      if (this.props.userId === null) {
        this.props.GTMSetUser({
          utm_campaign,
          utm_referrer,
          utm_medium,
          utm_source,
        });
      } else {
        this.props.GTMSetUserWithCallbackAction({
          utm_campaign,
          utm_referrer,
          utm_medium,
          utm_source,
        });
      }
    }
  }

  render() {
    const {
      faviconURL,
      notificationOpen,
      notificationPosition,
      message,
      dismissCloseableNotification,
      notificationType,
      autoCloseInterval,
      children,
    } = this.props;
    return (
      <div>
        <Favicon url={faviconURL} />
        <NoSSR>
          <MarketingParams />
          <ToastContainer
            hideProgressBar={true}
            position={'bottom-left'}
            closeButton={false}
            draggable={false}
            icon={false}
            newestOnTop={true}
          />
          <RedirectToExternalLinkModal />
          <LogoutListener />
          <LogoutUserWhenTokenIsNotValid />
          <Alert
            message={message}
            position={notificationPosition}
            isOpen={notificationOpen}
            onClose={dismissCloseableNotification}
            type={notificationType}
            autoClose={autoCloseInterval || this.props.defaultAutoCloseInterval}
          />
        </NoSSR>
        <MainContainer>{children}</MainContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notificationOpen: getNotificationState(state),
  message: getNotificationMessage(state),
  notificationType: getNotificationType(state),
  notificationPosition: getNotificationPosition(state),
  autoCloseInterval: state.notifications.closeable.autoCloseInterval,
  scopedTokenActionCompleted: getScopedTokenActionCompleted(state),
  defaultAutoCloseInterval: getConfig(state).DEFAULT_AUTO_CLOSE_INTERVAL,
  userId: getUserId(state),
  user: getUser(state),
  profileCompletionScore: getProfileCompletionScore(state),
});

const mapDispatchToProps = {
  dismissCloseableNotification,
  fetchGeneralConfig,
  logout,
  GTMSetUser,
  GTMSetUserWithCallbackAction,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MainLayout);
