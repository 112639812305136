import { Action, createAction } from 'redux-actions';

import { ReduxThunkAction } from 'src/global/store';

export type Actions = Action<any>;

export const Actions = {
  OPEN_SIDEBAR: 'glints/ui/Menu/OPEN_SIDEBAR',
  CLOSE_SIDEBAR: 'glints/ui/Menu/CLOSE_SIDEBAR',
  SET_INVERTED: 'glints/ui/Menu/SET_INVERTED',
  UNSET_INVERTED: 'glints/ui/Menu/UNSET_INVERTED',

  SET_PROTECTED: 'glints/ui/MainContainer/SET_PROTECTED',
  UNSET_PROTECTED: 'glints/ui/MainContainer/UNSET_PROTECTED',

  SET_LOGIN_FORM_HOVER: 'glints/ui/LoginForm/SET_LOGIN_FORM_HOVER',
  UNSET_LOGIN_FORM_HOVER: 'glints/ui/LoginForm/UNSET_LOGIN_FORM_HOVER',
  SET_SIGNUP_FORM_HOVER: 'glints/ui/SignUpForm/SET_SIGNUP_FORM_HOVER',
  UNSET_SIGNUP_FORM_HOVER: 'glints/ui/SignUpForm/UNSET_SIGNUP_FORM_HOVER',

  OPEN_EVENTS_LOGIN_SIGNUP_MODAL:
    'glints/ui/LoginModal/OPEN_EVENTS_LOGIN_SIGNUP_MODAL',
  CLOSE_EVENTS_LOGIN_SIGNUP_MODAL:
    'glints/ui/LoginModal/CLOSE_EVENTS_LOGIN_SIGNUP_MODAL',
  OPEN_LOGIN_MODAL: 'glints/ui/LoginModal/OPEN_LOGIN_MODAL',
  CLOSE_LOGIN_MODAL: 'glints/ui/LoginModal/CLOSE_LOGIN_MODAL',
  SET_LOGIN_SIGNUP_MODAL_TO_LOGIN:
    'glints/ui/LoginModal/SET_LOGIN_SIGNUP_MODAL_TO_LOGIN',
  OPEN_REPORT_MODAL: 'glints/ui/ActionModal/OPEN_REPORT_MODAL',
  CLOSE_REPORT_MODAL: 'glints/ui/ActionModal/CLOSE_REPORT_MODAL',

  OPEN_EXTERNAL_LINK_MODAL: 'glints/ui/Event/OPEN_EXTERNAL_LINK_MODAL',
  CLOSE_EXTERNAL_LINK_MODAL: 'glints/ui/Event/CLOSE_EXTERNAL_LINK_MODAL',

  OPEN_LOGIN_POPUP: 'glints/ui/LoginPopup/OPEN_LOGIN_POPUP',
  CLOSE_LOGIN_POPUP: 'glints/ui/LoginPopup/CLOSE_LOGIN_POPUP',

  OPEN_LOGIN_MODAL_FROM_SECTION:
    'glints/ui/LoginSection/OPEN_LOGIN_MODAL_FROM_SECTION',
};
export const openSidebar = createAction(Actions.OPEN_SIDEBAR);
export const closeSidebar = createAction(Actions.CLOSE_SIDEBAR);
export const setInverted = createAction(Actions.SET_INVERTED);
export const unsetInverted = createAction(Actions.UNSET_INVERTED);

export const setProtected = createAction(Actions.SET_PROTECTED);
export const unsetProtected = createAction(Actions.UNSET_PROTECTED);

export const setLoginFormHover = createAction(Actions.SET_LOGIN_FORM_HOVER);
export const unsetLoginFormHover = createAction(Actions.UNSET_LOGIN_FORM_HOVER);
export const setSignupFormHover = createAction(Actions.SET_SIGNUP_FORM_HOVER);
export const unsetSignupFormHover = createAction(
  Actions.UNSET_SIGNUP_FORM_HOVER
);

export const openLoginSignUpModal = createAction(
  Actions.OPEN_EVENTS_LOGIN_SIGNUP_MODAL
);
const closeLoginSignUpModalAction = createAction(
  Actions.CLOSE_EVENTS_LOGIN_SIGNUP_MODAL
);

export const setLoginSignupModalToLoginMode = createAction(
  Actions.SET_LOGIN_SIGNUP_MODAL_TO_LOGIN
);
export const closeLoginSignUpModal: () => ReduxThunkAction<Actions> =
  () => (dispatch) => {
    dispatch(closeLoginSignUpModalAction());
    dispatch(closeLoginSignupPopup());
  };

export const openReportModal = createAction(Actions.OPEN_REPORT_MODAL);
export const closeReportModal = createAction(Actions.CLOSE_REPORT_MODAL);

export const openExternalLinkModal = createAction(
  Actions.OPEN_EXTERNAL_LINK_MODAL
);
export const closeExternalLinkModal = createAction(
  Actions.CLOSE_EXTERNAL_LINK_MODAL
);

export const openLoginSignupPopup = createAction(Actions.OPEN_LOGIN_POPUP);
export const closeLoginSignupPopup = createAction(Actions.CLOSE_LOGIN_POPUP);

export const openLoginModalFromSection = createAction(
  Actions.OPEN_LOGIN_MODAL_FROM_SECTION
);
